.mini_map {
  position: relative;
  z-index: 10;

  @extend .d-flex;
  @extend .d-lg-block;
  @extend .justify-content-center;
  @extend .order-3;

  padding-top: 90px;
  padding-bottom: 10px;
  @extend .py-lg-0;
  @extend .px-4;
  @extend .px-sm-0;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 20vh;
    right: 60px;

    max-width: 250px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 320px;
  }
  svg {
    .map-btn rect,
    #Group_120 > path {
      fill: #ff8000;
    }
    .map-btn text {
      transition: all 0.25s ease;
    }
    .map-btn:hover text {
      fill: #733c05;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: -60px;
    left: 50%;
    z-index: 10;

    @extend .d-block;
    @extend .d-lg-none;
    margin-left: -70px;

    width: 139px;
    height: 132px;

    background: transparent url('/images/bestcamp.png') 0 0 no-repeat;
  }

  .map {
    width: 100%;
  }
}