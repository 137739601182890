.results-map {
  .gm-style {

    .gm-style-iw-d {
      margin: 15px;
      h2 {
        @extend .m-0;
        font-size: 1.25rem;
        font-weight: 700;
        font-style: normal;
        text-transform: none;
        color: #01608a;
      }
      .btn {
        @extend .btn-primary;
        @extend .mt-4;
        color: #fff;
        background-color: #ff8000!important;
        border-color: #ff8000!important;
        box-shadow: none!important;
      }
    }
    button {
      &.gm-ui-hover-effect {
        top: 0!important;
        right: 0!important;
      }
    }
    
  }
}

