.default {
    &.location_detail {
        .fancybox-btn {
            @extend .btn;
            @extend .btn-primary;
            @extend .px-3;
            @extend .py-1;

            opacity: 0.8;

            font-size: 15px;
            font-weight: lighter;

            & > i { margin-right: 5px; }

            &:hover { opacity: 1; }
        }
        .section_one {
            .intro {
                @extend .mb-4;

                .container-holder {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    @include media-breakpoint-down(xs) {
                        justify-content: space-around;
                        padding-bottom: 30px;
                    }

                    .back-to-overview {
                        @extend .d-flex;
                        @extend .align-self-center;

                        a {
                            @extend .d-flex;
                            @extend .flex-wrap;
                            @extend .align-self-center;
                            @extend .heading2;
                            font-size: 2rem!important;

                            i {
                                margin-right: 30px;
                                font-size: 1.8rem;
                                line-height: 3rem;
                                font-weight: 600;
                            }
                        }
                    }

                    .logo {
                        img {
                            height: 100px;
                        }
                    }
                }
            }

            .banner {
                margin-bottom: 60px;

                .container-holder {
                    position: relative;

                    .carousel {
                        @include media-breakpoint-up(sm) {
                            padding-right: 120px;
                        }

                        .carousel-item {
                            min-height: 300px;

                            @include media-breakpoint-up(sm) {
                                min-height: 640px;
                            }

                            .carousel-holder {
                                min-height: 300px;

                                @include media-breakpoint-up(sm) {
                                    min-height: 640px;
                                }
                            }
                        }

                        .carousel-indicators {
                            @extend .d-none;
                            @extend .d-sm-flex;
                        }
                    }

                    .highlight { 
                        position: relative;
                        z-index: 10;

                        max-width: 100%;
                        width: auto;

                        margin-top: -30px;
                        @extend .mx-4;
                        @extend .mx-sm-0;

                        @include media-breakpoint-up(sm) {
                            position: absolute;
                            top: 35px;
                            right: 0;

                            max-width: 230px;
                            width: 100%;
                            margin-top: 0;
                        }

                        .rating {
                            @extend .d-flex;
                            list-style: none;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }  
            }
            .video {
                @extend .text-center;
                margin-bottom: 30px;
            }
            .info {
                @extend .text-center;

                .container {
                    @extend .px-4;
                    @extend .px-sm-3;
                }

                .location_detail {

                        @extend .col-lg-7;
                        @extend .mx-auto;
                        @extend .mb-4;
                        @extend .px-0;
                    
                    h1, h2 {
                        @extend .heading1;
                    }
                
                }

                .btn {
                    @extend .btn-trigger;
                    display: inline;
                    padding-left: 60px;
                    padding-right: 60px;
                    text-transform: initial!important;
                }
            }

            .arrangements-slider {
                @extend .py-5;
                @extend .px-sm-5;

                .caption {
                    @extend .mb-3;
                }

                .slider {
                    .item {
                        padding: 0 .5rem 1rem .5rem;
                    }
                }
            }

            .characteristics {
                @extend .py-4;

                background-color: $blue;

                .container {
                    @extend .px-4;
                    @extend .px-sm-3;
                }

                .container-holder {
                    @extend .d-flex;
                    @extend .justify-content-center;

                    .list {
                        @extend .px-3;

                        // columns
                        -webkit-column-count: 1;
                        -moz-column-count: 1;
                        column-count: 1;
                        
                        -webkit-column-gap: 100px;
                        -moz-column-gap: 100px;
                        column-gap: 100px;

                        @include media-breakpoint-up(sm) {
                            -webkit-column-count: 2;
                            -moz-column-count: 2;
                            column-count: 2;
                        }

                        .list-item {
                            position: relative;
                            padding-left: 30px;

                            @extend .font-weight-bold;
                            color: $white;

                            // column
                            -webkit-column-break-inside: avoid;
                            -moz-page-break-inside: avoid;
                            break-inside: avoid;
                            -ms-column-break-inside: avoid;
                            -o-column-break-inside: avoid;
                            column-break-inside: avoid;
                            page-break-inside: avoid;

                            &::before {
                                position: absolute;
                                left: 0;
                        
                                font-family: "Font Awesome 5 Pro";
                                font-weight: 900;
                                line-height: 21px;
                                color: $white;
                                
                                content: "\f00c";
                            }
                               
                        }
                    }

                }
            }

            .accordion {
                background-color: $white;

                .container-fluid {
                    @extend .px-0;
                }
                
                .container {
                    .container {
                        @extend .px-4;
                        @extend .px-sm-3;
                    }
                }

                .heading {
                    @extend .py-4;
                    border-top: 2px solid $body-bg;

                    .container-holder {
                        @extend .container;

                        @extend .d-flex;

                        .icon {
                            flex: 0 0 20px;

                            a {
                                font-size: $font-size-base * 1.875;
                                color: $dark-blue;
                            }
                        }

                        .text {
                            flex: 1 1 auto;
                            @extend .pl-4;

                            .title {
                                @extend .mb-0;

                                font-size: $font-size-base * 1.25;
                                @extend .font-weight-bold;
                                color: $dark-blue;
                            }

                            .subtitle {
                                font-size: $font-size-base;
                            }
                        }
                    }
                }

                .description {
                    .container-holder {
                        @extend .container;

                        .items {
                            @include make-row();

                            .item {
                                @include make-col-ready();
                                @include make-col(12);

                                @include media-breakpoint-up(md) {
                                    @include make-col(6);
                                }
                                @include media-breakpoint-up(lg) {
                                    @include make-col(4);
                                }

                                @extend .mb-4;
                                @extend .mb-sm-5;

                                .content {
                                    @extend .pt-4;
                                    border-top: 2px solid $body-bg;

                                    .title {
                                        font-size: $font-size-base;
                                        @extend .font-weight-bold;
                                        color: $green;
                                    }

                                    .text {
                                        ul {
                                            margin-top: 1rem;
                                            padding: 0;

                                            list-style: none;

                                            li {
                                                position: relative;

                                                @extend .mb-2;
                                                padding-left: 30px;
                                                                        
                                                &::before {
                                                    position: absolute;
                                                    top: 0;
                                                    left: 0;
                                            
                                                    font-family: "Font Awesome 5 Pro";
                                                    font-size: $font-size-base * 0.75;
                                                    font-weight: 900;
                                                    line-height: 21px;
                                                    color: $green;
                                                    
                                                    content: "\f111";
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }
        
        .section_two {
			@extend .px-3;
			@extend .py-3;

			@extend .px-sm-5;
            @extend .py-sm-5;
            
            h1 {
                @extend .heading2;
                @extend .text-center;
            }
        }

        .section_three {
			@extend .px-3;
			@extend .py-3;

			@extend .px-sm-5;
            @extend .py-sm-5;
            
            .caption {
                @extend .text-center;
            }
        }

        .accommodation_arrangement,
        .location_arrangement {
            @extend .justify-content-center;

            .item {
                @include make-col-ready();
                @include make-col(12);

                @include media-breakpoint-up(md) {
                    @include make-col(6);
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(6);
                }
                @include media-breakpoint-up(xl) {
                    @include make-col(6);
                }

                @extend .mb-4;
                @extend .mb-sm-5;

                padding-left: $grid-gutter-width / 4;
                padding-right: $grid-gutter-width / 4;
            }
        }

        .accommodation_arrangement {
            .card-actions {
                @extend .mt-3;
                .card-btn{
                    @extend .btn;
                    @extend .btn-white;
                    display: inline;
                }
            }
        }
    }
}