.location_mini_search_book {
  position: absolute;
  bottom: 550px;
  left: 0;
  z-index: 20;

  @extend .order-2;

  width: 100%;
  @extend .px-4;
  @extend .px-lg-0;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 20vh;
    bottom: initial;
    left: 60px;

    max-width: 250px;
    margin-left: 0;
  }
  @include media-breakpoint-up(xl) {
    max-width: 315px;
  }

  h1 {
    max-width: 315px;
    margin-right: auto;
    margin-left: auto;

    @extend .text-center;
    @extend .text-sm-left;
    font-size: $font-size-base * 1.25;
    font-weight: $font-weight-bolder;
    color: $white;
  }

  .w3-minisearchbook-widget {
    max-width: 315px;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      max-width: 100%;
    }

    .widget-body {
      @include media-breakpoint-down(xs) {
        padding-right: 45px;
      }
    }
  }
}

.location_search_book {
  .w3-location-searchbook-widget {
    .w3-filter-collection {
      @include media-breakpoint-down(xs) {
        padding-right: 25px;
      }
    }
  }
}