.eyecatcher {
  position: relative;
  min-height: $eyecatcher-height;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  @extend .mb-5;

  &::after {
    content: '';
    position: absolute;
    bottom: -60px;
    left: 50%;
    z-index: 10;

    margin-left: -70px;

    width: 139px;
    height: 132px;

    background: transparent url('/images/bestcamp.png') 0 0 no-repeat;
  }

  .container-default {
    @extend .px-0;
  }

  .container-holder {
    @extend .d-flex;
    @extend .d-lg-block;

    @extend .flex-column;
  }

  .carousel {
    @extend .order-1;

    .carousel-item {
      min-height: $eyecatcher-height;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        background: #035477;
        opacity: 0.3;
    }

      .carousel-holder {
        min-height: $eyecatcher-height;

        .container {
          max-width: 100%;
          padding: 0 30px;
          @extend .px-sm-5;
        }
      }

      .carousel-caption {
        padding-top: 90px;
        padding-bottom: 20px;

        @extend .text-center;
        @extend .text-sm-left;

        @include media-breakpoint-up(lg) {
          padding-top: 150px;
        }
      }

      .carousel-title {
        font-family: $font-family-secondary;
        font-size: $font-size-base * 5;
      }

      .carousel-btn {
        @extend .btn;
        @extend .btn-tertiary;
      }
    }

    .carousel-indicators {
      @extend .d-none;
    }
  }

  &.large {
    min-height: $eyecatcher-height-lg;
    @include media-breakpoint-down(md) {
      min-height: $eyecatcher-height-lg-sm;
    }
    @extend .mb-0;

    .carousel {
      .carousel-item {
        min-height: $eyecatcher-height-lg;
        @include media-breakpoint-down(md) {
          min-height: $eyecatcher-height-lg-sm;
        }
  
        .carousel-holder {
          @extend .align-items-start;
          @extend .align-items-lg-center;
          min-height: $eyecatcher-height-lg;
          @include media-breakpoint-down(md) {
            min-height: $eyecatcher-height-lg-sm;
          }

          .container {
            max-width: 400px;
            @extend .px-4;
            @extend .px-sm-3;
          }
        }

        .carousel-caption {
          padding: 150px 0 450px 0;
          
          @extend .text-center;

          @include media-breakpoint-up(lg) {
            padding: 150px 0;
          }
        }
      }
    }
    &::after {
      body.home & {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
    body.home & {
      @include media-breakpoint-down(md) {
        box-shadow: none;
      }
    }
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    margin-top: -25px;
    margin-left: -25px;

    font-family: "Font Awesome 5 Pro";
    font-size: 50px;
    line-height: 50px;
    color: #BBBBBB;

    content: "\f1ce";

    animation: fa-spin 2s infinite linear;
  }
}

body.home {
  .eyecatcher.large {
    .carousel {
      .carousel-item {
        @include media-breakpoint-down(sm) {
          background-image: none !important;
          background: #0094d4;
        }
      }
    }
  }
}