.default {
	&.landings_page_extra {
		.section_intro,
		.section_aanbod,
		.section_blocks,
		.section_text_cols {
			h1:not(.carousel-title):not(.card-title):not(.card-subtitle),
			h2:not(.carousel-title):not(.card-title):not(.card-subtitle) {
				@extend .heading1;
			}

			h3:not(.carousel-title):not(.card-title):not(.card-subtitle),
			h4:not(.carousel-title):not(.card-title):not(.card-subtitle),
			h5:not(.carousel-title):not(.card-title):not(.card-subtitle),
			h6:not(.carousel-title):not(.card-title):not(.card-subtitle) {
				font-weight: 700;
				color: $blue;
			}

			.btn {
				@extend .btn-primary;
			}
		}

		.bg-green {
			@extend .bg-secondary;
			color: $white;
			h2 {
				@extend .heading2;
				color: $white !important;
			}
		}

		.section_title {
			h2 {
				@extend .heading2;
			}
		}

		.section_text_cols {
			.container-two-columns {
				.container-holder {
					.column.left,
					.column.right,
					.column.one,
					.column.two {
						@include make-col(6);
					}
				}
			}
		}

		.section_blocks {
			.inspiration {
				.card {
					.card-img-overlay {
						.card-caption {
							.card-buttons {
								a:not(.card-title-link):not(.card-btn) {
									font-size: $font-size-base * 0.875;
									font-weight: 900;
									text-transform: uppercase;
									text-decoration: underline;
									color: $white;
									&:hover {
										color: $white;
									}
								}
								.card-btn {
									@extend .btn-outline-white;
									@extend .mt-3;
								}
							}
						}
					}
				}
			}

			.card {
				.card-body {
					.card-caption {
						a:not(.card-title-link):not(.card-btn) {
							font-size: $font-size-base * 0.875;
							font-weight: 900;
							text-transform: uppercase;
							text-decoration: underline;
							color: $dark-blue;
							&:hover {
								color: $blue;
							}
						}
					}
				}

				&.location {
					.card-body {
						.card-caption {
							.card-btn {
								&:hover {
									text-decoration: underline;
									color: $blue;
								}
							}
						}
					}
				}

				&.icon {
					@extend .flex-column;
					@extend .justify-content-center;
					@extend .p-4;
					background-color: $blue;
					.card-image {
						@extend .mb-3;
						@extend .text-center;
						i {
							font-size: $font-size-base * 5;
							color: $white;
						}
					}
					.card-body {
						min-height: 0;
						@extend .p-0;
						.card-caption {
							@extend .align-items-center;
							flex: 1 1 100%;
							@extend .text-center;
							.card-title {
								@extend .order-1;
								@extend .mb-1;
								font-size: $font-size-base * 1.25;
								color: $white;
							}
							.card-subtitle {
								@extend .order-2;
								color: $white;
							}
							.card-text {
								@extend .order-3;
								font-size: $font-size-base * 0.75;
								color: $white;
							}
							.card-buttons {
								@extend .mt-3;
							}
						}
					}
				}
			}

			.carousel {
				.carousel-item {
					.carousel-title {
						font-family: $font-family-secondary;
						font-size: $font-size-base * 3.125;
						color: $blue;
					}

					.carousel-subtitle {
						font-size: $font-size-base * 1.5;
						color: $black;
					}

					.carousel-text {
						color: $black;
					}

					.carousel-btn {
						@extend .btn;
						@extend .btn-primary;
						@extend .mt-3;
					}

					a:not(.carousel-btn) {
						font-size: $font-size-base * 0.875;
						font-weight: 900;
						text-transform: uppercase;
						text-decoration: underline;
						color: $dark-blue;

						&:hover {
							color: $blue;
						}
					}
				}

				.carousel-indicators {
					@extend .d-none;
				}
			}

			.slider {
				.card {
					box-shadow: none !important;
				}
			}
		}

		.section_aanbod {
			.inspiration {
				.card {
					.card-img-overlay {
						.card-caption {
							.card-buttons {
								a:not(.card-title-link):not(.card-btn) {
									font-size: $font-size-base * 0.875;
									font-weight: 900;
									text-transform: uppercase;
									text-decoration: underline;
									color: $white;

									&:hover {
										color: $white;
									}
								}

								.card-btn {
									@extend .btn-outline-white;
									@extend .mt-3;
								}
							}
						}
					}
				}
			}

			.card {
				.card-body {
					.card-caption {
						a:not(.card-title-link):not(.card-btn) {
							font-size: $font-size-base * 0.875;
							font-weight: 900;
							text-transform: uppercase;
							text-decoration: underline;
							color: $dark-blue;

							&:hover {
								color: $blue;
							}
						}
					}
				}

				&.location {
					.card-body {
						.card-caption {
							.card-btn {
								&:hover {
									text-decoration: underline;
									color: $blue;
								}
							}
						}
					}
				}

				&.icon {
					@extend .flex-column;
					@extend .justify-content-center;
					@extend .p-4;

					background-color: $blue;

					.card-image {
						@extend .mb-3;
						@extend .text-center;

						i {
							font-size: $font-size-base * 5;
							color: $white;
						}
					}

					.card-body {
						min-height: 0;
						@extend .p-0;

						.card-caption {
							@extend .align-items-center;
							flex: 1 1 100%;
							@extend .text-center;

							.card-title {
								@extend .order-1;
								@extend .mb-1;

								font-size: $font-size-base * 1.25;
								color: $white;
							}

							.card-subtitle {
								@extend .order-2;
								color: $white;
							}

							.card-text {
								@extend .order-3;

								font-size: $font-size-base * 0.75;
								color: $white;
							}

							.card-buttons {
								@extend .mt-3;
							}
						}
					}
				}
			}

			.carousel {
				.carousel-item {
					.carousel-title {
						font-family: $font-family-secondary;
						font-size: $font-size-base * 3.125;
						color: $blue;
					}

					.carousel-subtitle {
						font-size: $font-size-base * 1.5;
						color: $black;
					}

					.carousel-text {
						color: $black;
					}

					.carousel-btn {
						@extend .btn;
						@extend .btn-primary;
						@extend .mt-3;
					}

					a:not(.carousel-btn) {
						font-size: $font-size-base * 0.875;
						font-weight: 900;
						text-transform: uppercase;
						text-decoration: underline;
						color: $dark-blue;

						&:hover {
							color: $blue;
						}
					}
				}

				.carousel-indicators {
					@extend .d-none;
				}
			}

			.slider {
				.card {
					box-shadow: none !important;
				}
			}
		}
	}
}
