.container-navbar {
	.container-holder {
		.column.default {

		}
		.column.navbar {
			@extend .flex-wrap;
		}
	}
}

.container-default {
	@extend .px-4;
	@extend .px-sm-3;

	.container-holder {

	}
}

.container-one-column {
	@extend .px-4;
	@extend .px-sm-3;

	.container-holder {
		@include make_row();

		.column.default {
			@include make-col-ready();
			@include make-col(12);
		}
	}
}

.container-two-columns {
	@extend .px-4;
	@extend .px-sm-3;

	.container-holder {
		@include make-row();

		@extend .mb-n3;

		.column.left,
		.column.right,
		.column.one,
		.column.two {
			@include make-col-ready();
			@include make-col(12);

			@extend .mb-3;

			@include media-breakpoint-up(md) {
				@include make-col(6);
			}
		}
	}
}

.container-three-columns {
	@extend .px-4;
	@extend .px-sm-3;

	.container-holder {
		@include make-row();

		@extend .mb-n3;

		.column.left,
		.column.middle,
		.column.right {
			@include make-col-ready();
			@include make-col(12);

			@extend .mb-3;

			@include media-breakpoint-up(md) {
				@include make-col(4);
			}
		}
	}
}

.container-four-columns {
	@extend .px-4;
	@extend .px-sm-3;

	.container-holder {
		@include make-row();

		@extend .mb-n3;

		.column.one,
		.column.two,
		.column.three,
		.column.four {
			@include make-col-ready();
			@include make-col(12);

			@extend .mb-3;

			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}

			@include media-breakpoint-up(lg) {
				@include make-col(3);
			}
		}
	}
}

.container-six-columns {
	@extend .px-4;
	@extend .px-sm-3;

	.container-holder {
		@include make-row();

		@extend .mb-n3;

		.column.one,
		.column.two,
		.column.three,
		.column.four,
		.column.five,
		.column.six {
			@include make-col-ready();
			@include make-col(12);

			@extend .mb-3;

			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}

			@include media-breakpoint-up(md) {
				@include make-col(4);
			}

			@include media-breakpoint-up(xl) {
				@include make-col(2);
			}
		}
	}
}