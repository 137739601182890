.grid {
	@include make-row();
	@extend .justify-content-center;
}

.inspiration {
	@include make-row();
}

.card-columns {
	.landings_page_extra &,
	.landings_page_sub &,
	.landings_page_extended &,
	.landings_page & {
		@extend .d-flex;
		@extend .justify-content-center;
		.item {
			@extend .m-3;
		}
	}

}
