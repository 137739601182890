.highlight {
	@extend .d-flex;
	@extend .flex-column;
	@extend .justify-content-around;

	max-width: 100%;
	width: auto;
	min-height: 570px;
	min-width: 230px;
	display: inline-flex !important;
	padding: 15px 20px;
	background-color: map_get($theme-colors, 'secondary');
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
	color: $white;

	.rating {
		@extend .d-flex;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.title {
		font-weight: 600;
	}

	.place {
		font-size: 0.75rem;
	}

	.sub-title {
		font-size: 0.75rem;
	}

	.map {
		position: relative;
		margin: 15px -15px;

		img {
			width: 100%;
			height: auto;
		}

		&::before {
			position: absolute;
			bottom: 0;
			left: 0;

			width: 0;
			height: 0;

			border: 0;
			border-right: 0px solid transparent;
			border-left: 150px solid #05A535;
			border-top: 35px solid transparent;

			content: '';
		}

		&::after {
			position: absolute;
			right: 0;
			bottom: 0;

			width: 0;
			height: 0;

			border: 0;
			border-left: 0px solid transparent;
			border-right: 150px solid #05A535;
			border-top: 35px solid transparent;

			content: '';
		}
	}

	.usps {
		.list {
			@extend .justify-content-center;
		}
	}
}
