.map {
    
    .map-link {
        path {
            transition: $transition-base;
        }
        &:hover {
            path {
                fill: $blue;
            }
        }
    }

}