/*  ==========================================================================
    Import
	========================================================================== */

/*  Google Webfont
    ========================================================================== */
	@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900');

/*  Webfontkit
    ========================================================================== */
	@font-face {
		font-family: 'Streetscript';
		src: 	url('~project/assets/webfonts/Streetscript.eot'); /* IE9 Compat Modes */
		src: 	url('~project/assets/webfonts/Streetscript.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       			url('~project/assets/webfonts/Streetscript.woff') format('woff'), /* Modern Browsers */
       			url('~project/assets/webfonts/Streetscript.ttf')  format('truetype'), /* Safari, Android, iOS */
       			url('~project/assets/webfonts/Streetscript.svg#e32236cd41d4e194e11446544c585b84') format('svg'); /* Legacy iOS */
		font-style:   normal;
		font-weight:  400;
	}
