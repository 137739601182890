.default {
	&.contact {
		.section_one {
			@extend .py-3;

			.container-default {
				@extend .py-3;
			}
		}
	}
}
@include media-breakpoint-down(xs) {
	h2 {
		span {
			&.nextline {
				clear: both;
				display: block;
				margin-top: 10px;
				font-size: 26px;
				a {
					color: #fff!important;
				}
			}
		}
	}
}
