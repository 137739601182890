.slider {
  .owl-stage-outer {
    .owl-stage {
      @extend .d-flex;

      .owl-item {
        .item {
          @extend .h-100;
        }
      }
    }
  }

  .owl-nav {
    position: absolute;
    top: 50%;
    right: -25px;
    left: -25px;

    transform: translateY(-50%);

    height: 0;

    @extend .d-flex;
    @extend .justify-content-between;
  }
}