.usps {
    @extend .mt-auto;
    @extend .text-center;
    
    .title {
        font-family: $font-family-secondary;
        font-weight: $font-weight-normal;
        font-size: 1rem;
    }

    .list {
        @extend .d-flex;
        @extend .flex-wrap;

        font-size: $font-size-base * 0.75;
        font-weight: $font-weight-bold;


        .list-item {
            @extend .mx-2;

            .icon {
                font-size: $font-size-base * 1.5;

                i {
                    color: $dark-blue;
                }

                img {
                    max-width: 40px;
                }
            }

            .label {
                max-width: 100px;
            }
        }
    }
}