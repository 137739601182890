.default {
	&.location_accommodation_detail {
		.section_one {

			.container-fluid {
				@extend .px-0;
			}


			.intro {

				@extend .mb-4;

				.container-holder {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					flex-wrap: wrap;

					@include media-breakpoint-down(sm) {
						justify-content: center;
						padding-bottom: 30px;
					}

					.back-to-overview {
						@extend .d-flex;
						@extend .align-self-center;

						a {
							@extend .d-flex;
							@extend .flex-wrap;
							@extend .align-self-center;
							@extend .heading2;
							font-size: 1.8rem !important;
							line-height: 3rem;

							i {
								margin-right: 30px;
								font-size: 1.8rem;
								line-height: 3rem;
								font-weight: 600;
							}

							.subtitle {
								margin-left: 10px;
								padding-left: 10px;
								border-left: 1px solid;
							}
						}
					}

					.logo {
						img {
							height: 100px;
						}
					}
				}
			}

			.banner {

				.container-holder {
					position: relative;
					@media(max-width: 991px) {
						display: flex;
						flex-flow: row wrap;
					}

					.carousel {
						height: 640px;
						@media(min-width: 992px) {
							padding-right: 75px;
						}
						@media(max-width: 991px) {
							flex: 0 0 100%;
							max-width: 100%;
						}

						.carousel-inner {
							height: 640px;
							@media(min-width: 992px) {
								padding-right: 75px;
							}

							.carousel-item {
								height: 640px;
								@media(min-width: 992px) {
									padding-right: 75px;
								}
							}
						}
					}

					.highlight {
						position: absolute;
						top: 50px;
						right: 0;
						z-index: 10;
						max-width: 350px;
						@extend .justify-content-start;
						@media(max-width: 991px) {
							position: initial;
							max-width: 100%;
							width: 100%;
						}

						.container {
							@extend .d-flex;
							@extend .flex-column;
							@extend .py-4;
							flex: 1;

							.title {
								font-size: 1.5rem;
								margin: 0;
							}

							.subtitle {
								font-size: 0.8rem;
							}

							.list {
								.list-item {
									position: relative;
									padding-left: 18px;

									&::before {
										position: absolute;
										top: 0;
										left: 0;

										font-family: "Font Awesome 5 Pro";
										font-weight: 900;
										line-height: 28px;
										font-size: 12px;
										color: $white;

										content: "\f111";
									}

									.fa-stack {
										margin-left: -12px;
									}
								}

								&.list-icons {
									.list-item {
										padding-left: 30px;

										.icon {
											position: absolute;
											top: 0;
											left: 0;
										}

										&::before {
											display: none;
										}
									}
								}
							}

							.btn {
								@extend .btn;
								@extend .btn-tertiary;

								margin-top: auto;
								padding-left: 5px !important;
								padding-right: 5px !important;
							}
						}
					}
				}
			}


			.info {
				padding: 60px;
				@extend .text-center;

				.location_accommodation_detail {

					@extend .col-lg-7;
					@extend .mx-auto;
					@extend .mb-4;

					h1, h2 {
						@extend .heading1;
					}

				}

				.btn {
					@extend .btn-trigger;
					display: inline;
					padding-left: 60px;
					padding-right: 60px;
					text-transform: initial !important;
				}
			}

			.characteristics {
				@extend .py-4;

				background-color: $blue;

				.container-holder {
					@extend .d-flex;
					@extend .justify-content-center;

					.list {
						@extend .px-3;

						// columns
						-webkit-column-count: 1;
						-moz-column-count: 1;
						column-count: 1;

						-webkit-column-gap: 100px;
						-moz-column-gap: 100px;
						column-gap: 100px;

						@include media-breakpoint-up(sm) {
							-webkit-column-count: 2;
							-moz-column-count: 2;
							column-count: 2;
						}

						.list-item {
							position: relative;
							padding-left: 30px;

							@extend .font-weight-bold;
							color: $white;

							// column
							-webkit-column-break-inside: avoid;
							-moz-page-break-inside: avoid;
							break-inside: avoid;
							-ms-column-break-inside: avoid;
							-o-column-break-inside: avoid;
							column-break-inside: avoid;
							page-break-inside: avoid;

							.icon {
								position: absolute;
								top: 0;
								left: 0;
							}

							&::before {
								display: none;
							}
						}
					}

				}
			}

			.accordion {
				background-color: $white;

				.container-fluid {
					@extend .px-0;
				}

				.heading {
					@extend .py-4;
					border-top: 2px solid $body-bg;

					.container-holder {
						@extend .container;

						@extend .d-flex;
						@extend .flex-wrap;

						.icon {
							flex: 0 0 20px;

							a {
								font-size: $font-size-base * 1.875;
								color: $dark-blue;
							}
						}

						.text {
							flex: 1 1 auto;
							@extend .pl-4;

							.title {
								@extend .mb-0;

								font-size: $font-size-base * 1.25;
								@extend .font-weight-bold;
								color: $dark-blue;
							}

							.subtitle {
								font-size: $font-size-base;
							}
						}
					}
				}

				.description {
					.container-holder {
						@extend .container;

						.items {
							@include make-row();

							.item {
								@include make-col-ready();
								@include make-col(12);

								@include media-breakpoint-up(md) {
									@include make-col(6);
								}
								@include media-breakpoint-up(lg) {
									@include make-col(4);
								}

								@extend .mb-4;
								@extend .mb-sm-5;

								.content {
									@extend .pt-4;
									border-top: 2px solid $body-bg;

									.title {
										font-size: $font-size-base;
										@extend .font-weight-bold;
										color: $green;
									}

									.text {
										ul {
											margin-top: 1rem;
											padding: 0;

											list-style: none;

											li {
												position: relative;

												@extend .mb-2;
												padding-left: 30px;

												&::before {
													position: absolute;
													top: 0;
													left: 0;

													font-family: "Font Awesome 5 Pro";
													font-size: $font-size-base * 0.75;
													font-weight: 900;
													line-height: 21px;
													color: $green;

													content: "\f111";
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.availability {

				.container {
					@extend .px-3;
					@extend .py-3;

					@extend .px-sm-5;
					@extend .py-sm-5;

					.container-holder {
						@extend .text-center;

						h1 {
							@extend .heading2;
						}
					}
				}
			}
		}
	}
}
