.default {
  .cookie-consent-banner {
    background-color: $white;

    color: $black;

    .cookie-container-right {
      @extend .justify-content-end;
      @extend .justify-content-md-center;

      background-color: transparent;
      border: none;
      border-top: 1px solid #CCCCCC;
      padding-bottom: 40px;

      @include media-breakpoint-up(md) {
        border: none;
        border-left: 1px solid #CCCCCC;
        padding-bottom: 0;
      }
    }

    .cookie-title {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      color: $blue;
    }

    .cookie-description {
      font-size: 14px;
    }

    .cookie-btn {
      @extend .btn;
      padding: 5px 10px !important;

      background-color: transparent;
      border-color: $blue;
      box-shadow: none !important;

      font-size: 14px;
      color: $blue;

      &:hover {
        background-color: $dark-blue;
        border-color: $dark-blue;

        color: $white;
      }
    }

    .cookie-close {
      color: $blue;

      &:hover {
        color: $black;
      }
    }
  }


  .cookie-consent {
    .list {
      border: 1px solid $black;
      opacity: 0.25;

      transition: $transition-base;

      &:hover {
        opacity: 1;

        &::before {
          content: '\f058';
        }
      }
    }

    .active {
      .list {
        border: 1px solid $black;
        opacity: 1;
      }
    }

    .cookie-list {
      ul {
        padding: 0;
        list-style: none;

        li {
          position: relative;

          &::before {
            position: absolute;
            left: 0;
            
            content: '\f057';
            font-family: "Font Awesome 5 Pro";
          }

          &.checked {
            color: $blue;

            &::before {
              content: '\f058';
            }
          }
        }
      }  
    }

    .cookie-btn {
      background-color: $blue;
      border-color: $blue;
      box-shadow: none !important;

      color: $white;

      &:hover {
        background-color: $dark-blue;
        border-color: $dark-blue;

        color: $white;
      }
    }
  }
}