// Buttons

.btn {
  padding: 12px 20px;
  @extend .shadow;

  &-secondary {
    color: $white;

    &:hover {
      background-color: $dark-blue;
      border-color: $dark-blue;
  
      color: $white;
    }
  }

  &-tertiary {
    color: $white;

    &:hover {
      background-color: $dark-blue;
      border-color: $dark-blue;
  
      color: $white;
    }
  }

  &-white {
    color: $dark-blue;

    &:hover {
      background-color: $dark-blue;
      border-color: $dark-blue;
  
      color: $white;
    }
  }
}

.btn-trigger {
  @extend .btn-block;
  
  background-color: $btn-bgcolor-trigger;
  border-color: $btn-bordercolor-trigger;

  font-weight: 900;
  @extend .text-uppercase;
  color: $white;
}

.btn-back {
  color: $blue;

  &::before {
    margin-right: 10px;
    font-family: "Font Awesome 5 Pro";
    content: '\f060';
  }

  &:hover {
    color: $orange;
  }
}

// Links

.text-link {
  font-size: $font-size-base * 0.875;
  font-weight: 900;
  @extend .text-uppercase;
  text-decoration: underline;
}