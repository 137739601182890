.default {
	&.blog_post_overview {
		.section_one {
			@extend .py-4;

			@extend .px-sm-5;
			@extend .py-sm-5;

			.blog_overview {
				.item {
					@media (max-width:400px) {
						height: max-content;						
					}
					.card{
						height: fit-content;
					}
					.card-image{
						height: 100%;
						.card-image-link{
							height: 100%;
						img{
							height: 100%;
							object-fit: cover;
							-o-object-fit: cover;
						}
					}
						
						
					}				
					.card-btn {
						@extend .btn;
						@extend .btn-white;

						margin-top: 0.8rem;
						display: block;
						align-self: start;
						align-tracks: start;
					}
				}

				&.masonry {
					display: grid;
					gap: 1rem;
					grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
					
					// column-count: 1;
					@include media-breakpoint-up(lg) {
						// column-count: 2;
						grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
					}
					@include media-breakpoint-up(xl) {
						// row-count: 2;
						grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
					
					}
				}
			}
			.caption {
				@extend .text-center;
			}
		}
	}

	&.blog_post_detail {
		.section_one {
			@extend .px-3;
			@extend .py-5;

			@extend .px-sm-5;

			.blog_detail {
				@include make-row();
				@extend .pb-0;

				.info {
					@include make-col-ready();
					@include make-col(12);

					@include media-breakpoint-up(lg) {
						@include make-col(8);
					}

					@extend .mb-4;
					@extend .pr-lg-5;

					h1 {
						@extend .heading1;
					}
				}

				.content {
					@include make-col-ready();
					@include make-col(12);

					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}

					@extend .mb-4;
					@extend .pl-lg-5;

					.gallery {
						.highlight {
							width: inherit;
							min-height: inherit;
							padding: 30px 15px 0;
							background-color: inherit;
							box-shadow: inherit;
							color: inherit;

							img {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
