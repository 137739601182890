.arrangement-filters-wrapper {
	margin-left: -10px;
	margin-right: -10px;
	text-align: center;

	.arrangement-filters {
		background: $primary;
		border-radius: 10px;
		display: inline-flex;
		padding: 10px 15px;
		margin-bottom: 15px;
		@include media-breakpoint-down(md) {
			flex-flow: row wrap;
		}

		.arrangement-input-wrapper {
			@include media-breakpoint-down(md) {
				flex: 0 0 100%;
				max-width: 100%;
			}

			> label {
				display: block;
				font-family: $font-family-primary;
				color: $white;
				font-weight: 600;
				margin-bottom: 5px;
			}

			> select {
				width: 100%;
				font-family: $font-family-primary;
				height: 32px;
				font-weight: 600;
				margin-bottom: 5px;
				cursor: pointer;
				@include media-breakpoint-up(lg) {
					min-width: 220px;
				}
			}

			&:not(:last-of-type) {
				margin-right: 15px;
			}
		}
	}
}
