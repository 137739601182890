.list {
    margin: 0;
    padding: 0;

    list-style: none;
}
.check-list {
    margin: 0;
    padding: 0;
    width: fit-content;

    list-style: none;
    li {
        position: relative;
        padding-left: 18px;

        &::before {
            position: absolute;
            left: 0;
    
            font-family: "Font Awesome 5 Pro";
            font-weight: 900;
            line-height: 21px;
            
            content: "\f00c";
        }
    }
}