.heading1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: $blue;
    margin: 0 0 15px;
}

.heading2 {
    font-size: 2rem;
    font-family: $font-family-secondary;
    font-weight: 400;
    color: $green;
    margin: 0 0 15px;

    @include media-breakpoint-up(sm) {
        font-size: 3.125rem;
    }
}
