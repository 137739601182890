.default {
	&.landings_page_sub {
		.section_intro,
		.section_offer,
    .section_aanbod,
    .section_content_field {
      h1:not(.carousel-title):not(.card-title):not(.card-subtitle),
      h2:not(.carousel-title):not(.card-title):not(.card-subtitle) {
        @extend .heading1;
      }

      h3:not(.carousel-title):not(.card-title):not(.card-subtitle),
      h4:not(.carousel-title):not(.card-title):not(.card-subtitle),
      h5:not(.carousel-title):not(.card-title):not(.card-subtitle),
      h6:not(.carousel-title):not(.card-title):not(.card-subtitle) {
        font-weight: 700;
        color: $blue;
      }

      .btn {
        @extend .btn-primary;
      }
    }

		.section_title {
			@extend .px-3;
			@extend .px-sm-4;
			@extend .pt-3;
			@extend .pt-sm-5;
			@extend .pb-2;
			@extend .pb-sm-4;

			@extend .bg-secondary;
      color: $white;

      h2 {
        @extend .heading2;
        color: $white !important;
      }
    }

		.section_offer {
			@extend .bg-secondary;
      color: $white;

      h1:not(.carousel-title):not(.card-title):not(.card-subtitle),
      h2:not(.carousel-title):not(.card-title):not(.card-subtitle),
      h3:not(.carousel-title):not(.card-title):not(.card-subtitle),
      h4:not(.carousel-title):not(.card-title):not(.card-subtitle),
      h5:not(.carousel-title):not(.card-title):not(.card-subtitle),
      h6:not(.carousel-title):not(.card-title):not(.card-subtitle) {
        color: $white !important;
      }
    }

    .section_aanbod {
      .inspiration {
        .card {
          .card-img-overlay {
            .card-caption {
              .card-buttons {
                a:not(.card-title-link):not(.card-btn) {
                  font-size: $font-size-base * 0.875;
                  font-weight: 900;
                  text-transform: uppercase;
                  text-decoration: underline;
                  color: $white;

                  &:hover {
                    color: $white;
                  }
                }

                .card-btn {
                  @extend .btn-outline-white;
                  @extend .mt-3;
                }
              }
            }
          }
        }
      }

      .card {
        .card-body {
          .card-caption {
            a:not(.card-title-link):not(.card-btn) {
              font-size: $font-size-base * 0.875;
              font-weight: 900;
              text-transform: uppercase;
              text-decoration: underline;
              color: $dark-blue;

              &:hover {
                color: $blue;
              }
            }
          }
        }

        &.location {
          .card-body {
            .card-caption {
              .card-btn {
                &:hover {
                  text-decoration: underline;
                  color: $blue;
                }
              }
            }
          }
        }

        &.icon {
          @extend .flex-column;
          @extend .justify-content-center;
          @extend .p-4;

          background-color: $blue;

          .card-image {
            @extend .mb-3;
            @extend .text-center;

            i {
              font-size: $font-size-base * 5;
              color: $white;
            }
          }

          .card-body {
            min-height: 0;
            @extend .p-0;

            .card-caption {
              @extend .align-items-center;
              flex: 1 1 100%;
              @extend .text-center;

              .card-title {
                @extend .order-1;
                @extend .mb-1;

                font-size: $font-size-base * 1.25;
                color: $white;
              }

              .card-subtitle {
                @extend .order-2;
                color: $white;
              }

              .card-text {
                @extend .order-3;

                font-size: $font-size-base * 0.75;
                color: $white;
              }

              .card-buttons {
                @extend .mt-3;
              }
            }
          }
        }
      }

      .carousel {
        .carousel-item {
          .carousel-title {
            font-family: $font-family-secondary;
            font-size: $font-size-base * 3.125;
            color: $blue;
          }

          .carousel-subtitle {
            font-size: $font-size-base * 1.5;
            color: $black;
          }

          .carousel-text {
            color: $black;
          }

          .carousel-btn {
            @extend .btn;
            @extend .btn-primary;
            @extend .mt-3;
          }

          a:not(.carousel-btn) {
            font-size: $font-size-base * 0.875;
            font-weight: 900;
            text-transform: uppercase;
            text-decoration: underline;
            color: $dark-blue;

            &:hover {
              color: $blue;
            }
          }
        }

        .carousel-indicators {
          @extend .d-none;
        }
      }

      .slider {
        .card {
          box-shadow: none !important;
        }
      }
    }

		.section_content_field {
			@extend .p-0;
			.container-holder {
				.column {
					@extend .p-0;
				}
			}

			.content_left {
				.column.one {
					@extend .p-4;
					@include media-breakpoint-up(md) {
						padding: 5% 50px 30px 10%!important;
					}
				}
			}
			.content_right {
				.column.two {
					@extend .p-4;
					@include media-breakpoint-up(md) {
						padding: 5% 10% 30px 50px!important;
					}
				}
			}

			.resource_image {
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}
