.default {
	&.home {
		.section_one {
			@extend .text-center;
		}

		.section_two {
			@extend .pt-0;
			@extend .pb-5;

			.container-default {
				@extend .px-0;
			}
		}

		.section_three {

		}

		.section_four {
			padding: 30px 0;
			background-color: $white;

			.contentblock.grid {
				.item {
					@include make-col-ready();
					@include make-col(12);

					@extend .mb-4;
					@extend .mb-md-0;
					@extend .px-4;
					@extend .px-md-1;
			
					@include media-breakpoint-up(md) {
						@include make-col(4);
					}
					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}

					.card {
						&.icon {
							@extend .flex-row;

							@extend .shadow-none;

							.card-image {
								@extend .pr-3;

								i {
									font-size: $font-size-base * 3.75;
									color: $green;
								}
							}

							.card-body {
								@include media-breakpoint-up(md) {
									flex: 0 1 135px;
								}

								.card-caption {
									.card-subtitle {					
										font-size: $font-size-base * 0.75;
										color: $gray-700;
									}
					
									.card-title {
										@extend .mb-0;
					
										font-size: $font-size-base * 0.875;
										color: $gray-700;
									}
								}
							}
						}
					}

					&:last-child {
						@extend .mb-0;
					}
				}
			}
		}

		.section_five {
			.contentblock.grid {
				.item {
					@include make-col-ready();
					@include make-col(12);
			
					@include media-breakpoint-up(md) {
						@include make-col(4);
					}
					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}

					@extend .mb-4;
					@extend .mb-md-0;

					padding-left: $grid-gutter-width / 4;
					padding-right: $grid-gutter-width / 4;

					.card {
						&.icon {
							@extend .flex-column;

							@extend .p-4;
							background: $blue;

							.card-image {
								@extend .mb-3;

								@extend .text-center;

								i {
									font-size: $font-size-base * 5;
									color: $white;
								}
							}

							.card-body {
								.card-caption {
									flex: 1 1 100%;
									@extend .align-items-center;

									@extend .text-center;

									.card-title {
										@extend .mb-1;

										font-size: $font-size-base * 1.25;
										color: $white;
									}

									.card-text {					
										font-size: $font-size-base * 0.75;
										color: $white;
									}
								}
							}
						}
					}

					&:last-child {
						@extend .mb-0;
					}
				}
			}
		}

		.section_six {
			@extend .text-center;
		}

		.section_seven {
			@extend .pt-0;
			@extend .pb-5;

			.container-default {
				@extend .px-0;
			}
		}

		.section_eight {
			padding: 70px 0;
		}
	}
}
