@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #ffffff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000000;

$blue:    		#0094D4;
$dark-blue:		#01608A;

$indigo:  		#6610f2;
$purple:  		#6f42c1;
$pink:    		#e83e8c;
$red:     		#dc3545;
$orange:  		#FF8000;
$yellow:  		#ffc107;

$green:   		#05A535;
$light-green:	#6FB368;

$teal:    		#20c997;
$cyan:    		#17a2b8;

$primary:       $blue;
$secondary:     $green;
$tertiary:			$orange;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: (
	"primary":    $primary,
	"secondary":  $secondary,
	"tertiary":		$tertiary,
	"success":    $success,
	"info":       $info,
	"warning":    $warning,
	"danger":     $danger,
	"light":      $light,
	"dark":       $dark,
	"white":			$white,
	"green":			$light-green
);


// Custom variables
$eyecatcher-height:					25vh;
$eyecatcher-height-lg: 			75vh;
$eyecatcher-height-lg-sm: 	50vh;

// Body
//
// Settings for the `<body>` element.

$body-bg:						#EFEFEF;
$body-color: 				#000000;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-primary: 			'Source Sans Pro', sans-serif;
$font-family-secondary: 		'Streetscript', sans-serif;

$font-family-base:					$font-family-primary;
$font-size-base:						1rem; // Assumes the browser default, typically `16px`
$line-height-base:        	1.428571429;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         	.5rem;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;
$btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;
$btn-line-height-lg:          $input-btn-line-height-lg !default;

$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight: 						900;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 					1.5rem;
$btn-border-radius-lg: 				2rem;
$btn-border-radius-sm: 				1rem;

$btn-bgcolor-trigger: 				$orange;
$btn-bordercolor-trigger: 		$orange;



// 3W MEDIA STYLING

// theme styling
$theme-color-primary: 			map_get($theme-colors, 'primary');
$theme-color-primary-dark: 		darken($theme-color-primary, 10%);

$theme-color-secondary: 		map_get($theme-colors, 'secondary');
$theme-color-secondary-dark: 	darken($theme-color-secondary, 10%);

$theme-bgcolor-tertiary: 		map_get($theme-colors, 'tertiary');
$theme-color-secondary-dark: 	darken($theme-bgcolor-tertiary, 10%);

// links
$link-hover-decoration:         none;

// TOMMY
$zeb-color-primary: 			$theme-color-primary;
$zeb-color-secondary: 			$theme-color-secondary;
$zeb-color-tertiary: 			$theme-color-primary-dark;
