.header {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 999;

  @include media-breakpoint-down(md) {
    position: relative;
  }

  transition: $transition-base;

  .header-main {
    @extend .py-3;
    @extend .pt-lg-5;
    @extend .pb-lg-0;

    background-color: $primary;

    transition: $transition-base;

    @include media-breakpoint-up(lg) {
      background-color: transparent;
    }

    .container-navbar {
      @extend .px-4;
      @extend .px-sm-5;
    }

    .column.navbar {
      @extend .navbar-expand-lg;
      @extend .flex-wrap;
      @extend .flex-lg-nowrap;
      @extend .align-items-center;
      @extend .align-items-lg-start;
      @extend .p-0;
    }

    .logo {
      max-width: 100px;
      transition: $transition-base;

      a {
        @extend .d-block;

        img {
          @extend .w-100;
        }
        &::after {
          body:not(.home) & {
            content: 'Voel je vrij...';
            position: relative;
            display: block;
            color: #fff;
            font-family: $font-family-secondary;
            font-size: 14px;
            line-height: 1;
            opacity: 1;
            transition: all 0.5s ease;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        max-width: 200px;
        body.home & {
          max-width: 315px;
        }
        a {
          &::after {
            body:not(.home) & {
              font-size: 25px;
            }
          }
        }
      }
    }

    .mini_search_book {
      @extend .ml-3;
    }

    .navbar-toggler {
      @extend .ml-auto;

      text-transform: uppercase;
      color: $white;

      .navbar-toggler-icon {
        width: auto;
        height: auto;    
      }
    }
    
    .navbar-collapse {
      .navbar-nav { 
        @extend .ml-auto;

        .nav-item {
          font-size: $font-size-base * 1.125;
          font-weight: 600; 

          .nav-link {
            color: $white;

            &:hover {
              color: rgba( $white, .7 );
            }

            @include media-breakpoint-up(lg) {
              padding: 0 15px;
            }
          }

          .dropdown-menu {
            @extend .m-0;
            padding: 5px 0;
            @extend .border-0;

            .nav-item {
              @extend .p-0;

              font-weight: $font-weight-normal;

              .nav-link {
                padding: 5px 10px;
                color: $black;

                &:hover {
                  color: $blue;
                }
              }
            }
          }

          &.active {
            > .nav-link {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .site_switcher {
      .language {
        .nav-item {
          font-size: $font-size-base * 1.125;
          font-weight: 600; 

          .nav-link {
            color: $white;

            &:hover {
              color: rgba( $white, .7 );
            }

            @include media-breakpoint-up(lg) {
              padding: 0 15px;
            }
          }

          .dropdown-menu {
            @extend .m-0;
            padding: 5px 0;
            @extend .border-0;

            .dropdown-item {
              padding: 5px 10px;
              background-color: transparent;

              font-size: $font-size-base * 1.125;
              font-weight: $font-weight-normal;
              color: $black;

              &:hover {
                color: $blue;
              }
            }
          }
        }
      }
    }
  }

  &.sticky {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

    .header-main {
      @extend .py-3;
      background-color: $primary;

      .column.navbar {
        @extend .align-items-center;
      }
      
      .logo {
        max-width: 150px;
        body.home & {
          max-width: 150px;
        }
        a {
          &::after {
            body:not(.home) & {
              font-size: 0;
              opacity: 0;
            }
          }
        }
      }
    }
  }
}