.default {
	&.accommodation_overview,
	&.location_accommodation_overview,
	&.location_overview {
		.section_one {
			@extend .px-3;
			@extend .py-3;

			@extend .px-sm-5;
			@extend .py-sm-5;
			
			.accommodation_overview,
			.location_accommodation_overview,
			.location_overview {
				@extend .justify-content-center;

				.item {
					@include make-col-ready();
					@include make-col(12);

					@include media-breakpoint-up(md) {
						@include make-col(6);
					}
					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}

					@extend .mb-4;
					@extend .mb-sm-5;

					padding-left: $grid-gutter-width / 4;
					padding-right: $grid-gutter-width / 4;
				}
			}
		}
	}

	&.accommodation_arrangement_overview {
		.section_one {
			@extend .px-3;
			@extend .py-3;

			@extend .px-sm-5;
			@extend .py-sm-5;

			.accommodation_arrangement,
			.location_arrangement {
				.item {
					@include make-col-ready();
					@include make-col(12);

					@include media-breakpoint-up(md) {
							@include make-col(6);
					}
					@include media-breakpoint-up(lg) {
							@include make-col(4);
					}
					@include media-breakpoint-up(xl) {
							@include make-col(4);
					}

					@extend .mb-3;
					@extend .mb-sm-5;

					padding-left: $grid-gutter-width / 8;
					padding-right: $grid-gutter-width / 8;
				}
			}
		}
	}

	&.accommodation_detail {
		.section_one {
			@extend .px-3;
			@extend .py-5;

			@extend .px-sm-5;

			.accommodation_detail {
				@include make-row();
				@extend .pb-5;

				.info {
					@include make-col-ready();
					@include make-col(12);

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@extend .mb-4;
					@extend .pr-lg-5;
				}

				.content {
					@include make-col-ready();
					@include make-col(12);

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@extend .mb-4;
					@extend .pl-lg-5;
				}

				.tommy {
					@include make-col-ready();
					@include make-col(12);
				}
			}
		}
	}
}