.carousel {
  .carousel-inner {
    overflow: inherit;
  }

  .carousel-item {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .carousel-holder {
      @extend .d-flex;
      @extend .flex-wrap;
      @extend .flex-md-nowrap;
      @extend .align-items-center;
    }

    .carousel-caption {
      position: relative;
      right: unset;
      bottom: unset;
      left: unset;
    }
  }

  //  indicators
  .carousel-indicators {
    bottom: -50px;

    li {
      background-color: map_get($theme-colors, 'primary');
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  // default
  &.default {
    padding-left: 0;


    @include media-breakpoint-up(md) {
      padding-left: 20vw;
    }

    .carousel-item {
      min-height: 700px;

      background-position: top center;
      background-size: auto 300px;

      @include media-breakpoint-up(md) {
        background-position: center center;
        background-size: cover;
      }

      .carousel-holder {
        padding-top: 270px;
        @extend .px-4;
        @extend .px-md-0;

        @include media-breakpoint-up(md) {
          padding-top: 0;
        }
      }

      .container {
        @extend .px-0;
      }

      .carousel-caption {
        @extend .py-0;
        text-align: left;
      }

      .carousel-title {
        font-family: $font-family-secondary;
        font-size: 1.875rem;
        font-weight: normal;
        margin-bottom: 30px;
      }

      .carousel-subtitle {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .carousel-btn {
        @extend .btn;
        @extend .btn-green;
      }

      .highlight {
        min-height: 0;
        margin: 0 auto;
        padding: 35px 15px 50px 15px;

        @include media-breakpoint-up(md) {
          min-height: 570px;
          margin: 50px 0 auto -150px;
          padding: 30px 15px;
        }
      }
    }
  }

  // location 
  &.location {
    padding-right: 0;

    @include media-breakpoint-up(md) {
      padding-right: 20vw;
    }

    .carousel-inner {
      @extend .px-4;
      @extend .px-md-0;
    }
    
    .carousel-item {
      background-position: top center;
      background-size: auto 265px;

      @include media-breakpoint-up(sm) {
        background-size: cover;
      }

      @include media-breakpoint-up(md) {
        min-height: 700px;

        background-position: center center;
        background-size: cover;
      }

      .carousel-holder {
        @extend .px-0;

        .container {
          @extend .px-0;
          @extend .px-md-3;

          @include media-breakpoint-up(sm) {
            max-width: 100%;
          }
        }
      }

      .carousel-caption {
        text-align: left;
        padding: 200px 15px 15px 15px;

        @include media-breakpoint-up(md) {
          padding: 150px 30px;
        }
      }

      .carousel-title {
        @extend .d-none;
        @extend .d-md-block;
        
        font-size: 1.25rem;
        font-weight: 600;
      }

      .carousel-subtitle {
        @extend .d-none;
        @extend .d-md-block;
        
        font-family: $font-family-secondary;
        font-size: 3.125rem;
        font-weight: normal;
        margin-bottom: 15px;
      }

      .carousel-btn {
        @extend .btn;
        @extend .btn-white;

        color: $theme-color-primary !important;

        &:hover {
          color: $white !important;
        }
      }
        
      .highlight {
        max-width: 100%;
        min-height: 0;
        margin: 0 auto;
        padding: 35px 15px 50px 15px;

        background-color: $white;

        color: $black;

        @include media-breakpoint-up(md) {
          max-width: 230px;
          min-height: 570px;
          margin: 50px -150px auto 0;
          padding: 30px 15px;

          background-color: $green;;

          color: $white;
        }

        .rating {
          @extend .d-none;
          @extend .d-md-flex;
        }

        .title {
          font-size: $font-size-base * 1.25;
          color: $dark-blue;

          @include media-breakpoint-up(md) {
            font-size: $font-size-base;
            color: $white;
          }
        }

        .map {
          @extend .d-none;
          @extend .d-md-flex;
        }

        .usps {
          @extend .mt-3;
          @extend .mt-md-auto;
          
          @extend .text-left;
          @extend .text-md-center;

          .list {
            @extend .justify-content-start;
            @extend .justify-content-md-center;

            .list-item {
              .label {
                color: $blue;

                @include media-breakpoint-up(md) {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

.default {
  &.location_detail,
  &.location_accommodation_detail {
    .carousel {
      .fancybox-buttons {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;

        @extend .d-flex;
        @extend .justify-content-center;
        width: 100%;
        padding: 45px 15px;

        @include media-breakpoint-up(sm) {
          padding: 30px 15px;
        }

        .fancybox-btn {
          @extend .btn;
          @extend .btn-primary;
          @extend .px-3;
          @extend .py-1;

          opacity: 0.8;

          font-size: 15px;
          font-weight: lighter;

          & > i { margin-right: 5px; }

          &:hover { opacity: 1; }
        }
      }
    }

    .eyecatcher {
      .carousel {
        .fancybox-buttons {
          @extend .d-none;
        }
      }
    }
  }

  &:not(.location_detail):not(.location_accommodation_detail) {
    .carousel {
      .fancybox-buttons {
        @extend .d-none;
      }
    } 
  }
}