.modal-header {
  .close {
    @extend .d-block;
    cursor: pointer;
  }
}

.modal-body {
  img {
    max-width: 400px;
  }
}