$gallery-margin: -10px -5px 0 -5px;
$gallery-margin_sm: -20px -10px 0 -10px;

$gallery-item-margin: 10px 0 0 0;
$gallery-item-margin_sm: 20px 0 0 0;
$gallery-item-padding: 0 5px;
$gallery-item-padding_sm: 0 10px;

$gallery-caption-background: rgba($black,.5);

$gallery-caption-font_size: $font-size-base * 1.5;
$gallery-caption-font_size_sm: $font-size-base * 2;
$gallery-caption-color: $white;

.gallery {
  @include make-row();
  margin: $gallery-margin;

  @include media-breakpoint-up(sm) {
    margin: $gallery-margin_sm;
  }

  .gallery-item {
    @include make-col-ready();
    @include make-col(6);
    margin: $gallery-item-margin;
    padding: $gallery-item-padding;

    transition: $transition-base;

    &:hover {
      transform: scale(1.025);
    }

    @include media-breakpoint-up(sm) {
      margin: $gallery-item-margin_sm;
      padding: $gallery-item-padding_sm;
    }
    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }

    .gallery-link {
      position: relative;
      @extend .d-block;
    }

    .gallery-img {
      @extend .img-fluid;
      @extend .w-100;
    }

    .gallery-caption {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;

      @extend .align-items-center;
      @extend .justify-content-center;

      background: $gallery-caption-background;
      font-size: $gallery-caption-font_size;
      color: $gallery-caption-color;

      &::before {
        content: '+';
      }

      @include media-breakpoint-up(sm) {
        font-size: $gallery-caption-font_size_sm;
      }
    }

    &.large-item {
      @include make-col(12);
    }

    &.first-item {
      .gallery-caption {
        @extend .d-none;
      }
    }

    &.before-last-item {
      .gallery-caption {
        @extend .d-flex;
        @extend .d-lg-none;
      }
    }

    &.last-item {
      @extend .d-none;
      @extend .d-lg-flex;

      .gallery-caption {
        @extend .d-none;
        @extend .d-lg-flex;
      }
    }
  }

  .gallery-hidden {
    @extend .d-none;
  }
}