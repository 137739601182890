.card {
    @extend .h-100;
    @extend .border-0;
    @extend .shadow;

    .card-image {
        position: relative;

        .card-image-link {
            @extend .d-block;
        }

        .card-caption {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            @extend .d-flex;
            @extend .align-items-center;
            padding: $card-img-overlay-padding * 1.5;
            background-color: rgba($dark, 0.1);

            .card-label {
                @extend .d-none;

                font-family: $font-family-secondary;
                font-size: $font-size-base * 2.5;
                color: $white;
            }
        }
    }

    .card-img-overlay {
        @extend .d-flex;
        @extend .align-items-center;
        padding: $card-img-overlay-padding * 1.5;

        background-color: rgba($dark, 0.5);
        transition: $transition-base;

        .card-caption {
            @extend .d-flex;
            @extend .flex-column;

            min-width: 0;

            @extend .text-white;

            .card-title-link {
                @extend .order-2;
                color: $white;

                &:hover {
                    text-decoration: underline;
                }
            }

            .card-title {
                @extend .order-2;
                @extend .mb-0;

                font-size: $font-size-base * 1.25;
                @extend .font-weight-bold;
            }

            .card-subtitle {
                @extend .d-block;
                @extend .order-1;

                @extend .mw-100;
                @extend .mt-0;
                @extend .text-truncate;

                font-size: $font-size-base * 0.75;
            }

            .card-text {
                @extend .order-3;

                @extend .font-weight-light;
            }

            .card-buttons {
                @extend .order-4;
                @extend .mr-auto;
                @extend .mt-auto;

                .card-btn {
                    @extend .btn;
                    @extend .btn-outline-primary;
                }
            }
        }
    }

    .card-body {
        @extend .d-flex;
        @extend .flex-wrap;

        padding: $card-spacer-x * 1.5;

        .card-caption {
            @extend .d-flex;
            @extend .flex-column;
            @extend .align-items-start;
            @extend .h-100;

            .card-label {
                @extend .mb-3;

                font-family: $font-family-secondary;
                font-size: $font-size-base * 2.5;
                color: $blue;
            }

            .card-title-link {
                @extend .order-2;

                &:hover {
                    text-decoration: underline;
                }
            }

            .card-title {
                @extend .order-2;

                font-size: $font-size-base * 1.25;
                @extend .font-weight-bold;
                color: $dark-blue;
            }

            .card-subtitle {
                @extend .order-1;
                @extend .mt-0;

                font-size: 0.875rem;
                color: $black;
                margin: 0 0 5px;
            }

            .card-text {
                @extend .order-3;
            }

            .card-buttons {
                @extend .order-4;
                @extend .mt-auto;


                .card-btn {
                    @extend .btn;
                    @extend .btn-white;
                    @extend .mt-auto;
                }
            }
        }
    }

    &:not(.icon):not(.location):not(.wide):not(.card-arrangement.wide) {
        .card-body {
            min-height: 350px;
        }
    }

    &:hover {
        .card-image {
            .card-img-overlay {
                background-color: rgba($dark, 0.3);
            }
        }

        .card-img-overlay {
            background-color: rgba($dark, 0.3);
        }
    }
}


// Card - Icon
//
.card.icon {
    @extend .d-flex;
    @extend .justify-content-center;

    .card-body {
        @extend .p-0;

        .card-caption {
            .card-subtitle {
                @extend .order-2;
            }

            .card-title {
                @extend .order-1;
            }
        }
    }
}


// Card - Location
//
.card.location {
    .card-body {
        padding: $card-spacer-x;

        .card-caption {
            @extend .mb-3;

            @extend .mb-xl-0;
            @extend .pr-xl-3;

            @include media-breakpoint-up(xl) {
                flex: 1;
            }

            .card-text {
                font-size: $font-size-base * 0.75;
            }

            .card-btn {
                @extend .order-4;

                @extend .text-link;
                @extend .mt-3;
                @extend .p-0;

                @extend .bg-transparent;
                @extend .border-0;
                @extend .shadow-none;

                color: $dark-blue;
            }
        }

        .card-map {
            position: relative;
            flex: 1 1 100%;

            @include media-breakpoint-up(xl) {
                flex: 0 1 150px;
                margin-top: -35px;
            }

            .card-map-img {
                @extend .img-fluid;
                @extend .w-100;
            }
        }

        .usps {
            flex: 1 1 100%;
            @extend .mt-3;
            @extend .mt-xl-5;

            .list {
                .list-item {
                    .icon { color: $dark-blue; }
                    .label { color: $blue; }
                }
            }
        }
    }
}


// Card - Wide
//
.card.wide {
    @extend .flex-row;
    @extend .flex-wrap;

    .card-image {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(sm) {
            @include make-col(4);
        }

        @extend .p-0;
    }

    .card-body {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(sm) {
            @include make-col(8);
        }
    }
}

// Card - Arrangement
//
.card.card-arrangement {
    .card-body {
        .card-caption {
            @extend .w-100;
            height: auto !important;

            .card-title {
                order: 1;
                @extend .w-100;
            }

            .card-text {
                order: 2;
                @extend .w-100;
            }
        }

        .card-info {
            @extend .d-flex;
            @extend .flex-wrap;
            @extend .w-100;

            .card-traveldata {
                @include make-col(12);

                @include media-breakpoint-up(md) {
                    @include make-col(6);
                }

                .card-arrival-label {
                    font-weight: $font-weight-bold;
                }
            }

            .card-prices {
                @include make-col(12);

                @extend .mt-3;
                @extend .mt-md-0;

                @include media-breakpoint-up(md) {
                    @include make-col(6);
                }

                @extend .mb-3;

                .card-price-label {
                    @extend .font-weight-bold;
                }

                .card-price {
                    font-size: $font-size-lg;
                    @extend .font-weight-bold;
                    color: #FF8000;
                }
            }
        }

        .card-spacer {
            @extend .w-100;
        }

        .card-properties {
            @extend .w-100;
        }

        .card-actions {
            @extend .w-100;

            .card-btn {
                @extend .btn;
                @extend .mt-3;

                background-color: #FF8000;
                border-color: #FF8000;

                color: #FFFFFF;

                &:hover {
                    background-color: #01608A;
                    border-color: #01608A;

                    color: #FFFFFF;
                }
            }

            .card-btn-prices {
                @extend .text-left;
            }
        }
    }

    &.wide {
        .card-body {
            .card-caption {
                @include make-col-ready();
                @include make-col(12);

                @include media-breakpoint-up(md) {
                    @include make-col(8);
                }
            }

            .card-info {
                @include make-col-ready();
                @include make-col(12);

                @include media-breakpoint-up(md) {
                    @include make-col(4);
                }
            }

            .card-spacer {
                @include make-col-ready();
                @include make-col(12);
            }

            .card-properties {
                @include make-col-ready();
                @include make-col(12);

                @include media-breakpoint-up(md) {
                    @include make-col(8);
                }
            }

            .card-actions {
                @include make-col-ready();
                @include make-col(12);

                @include media-breakpoint-up(md) {
                    @include make-col(4);
                }
            }
        }
    }
}
