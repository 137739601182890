.footer {
  @extend .py-5;

  background-color: $blue;

  @extend .text-center;

  .footer-top {
    @extend .mb-5;
    @extend .text-white;

    .footer_text {
      h3 {
        @extend .mb-0;
        @extend .font-weight-bold;
      }

      a {
        @extend .font-weight-bold;
        color: $white;

        &:hover {
          @extend .text-decoration-none;
          color: $dark-blue;
        }
      }
    }

    .footer_logolink {
      .list {
        @extend .d-flex;
        @extend .flex-wrap;
        @extend .justify-content-center;

        .list-item {
          @extend .mx-2;
          @extend .my-1;

          .link {
            @extend .d-block;
            width: 70px;

            background-color: $white;
            @extend .rounded-circle;
            box-shadow: 0 0 0 0 $white;
            
            font-size: $font-size-base * 1.25;
            line-height: 70px;
            color: $dark-blue;

            transition: $btn-transition;

            &:hover,
            &.focus {
              background-color: $dark-blue;
              box-shadow: 0 0 0 $input-btn-focus-width rgba($dark-blue, .25);

              color: $white;
            }
          }
          &:last-child {
            @extend .d-sm-none;
          }
        }
      }

      &.fixed-socials {
        position: fixed;
        top: 40vh;
        right: 0;
        z-index: 999;

        .list {
          @extend .flex-column;
          @extend .flex-nowrap;

          .list-item {
            .link {
              width: 50px;
              line-height: 50px;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              @extend .d-none;
            }
            &:nth-child(5) {
              @extend .d-block;
              @extend .d-sm-none;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    @extend .my-4;
    @extend .text-white;

    .footer_list {
      .list {
        .list-item {
          @extend .my-2;

          .link {
            @extend .font-weight-bold;
            color: $white;

            &:hover {
              @extend .text-decoration-none;
              color: $dark-blue;
            }
          }
        }
      }
    }
  }

  .footer-socket {
    @extend .mt-5;
    @extend .text-white;

    .footer_list {
      .list {
        @extend .d-sm-flex;
        @extend .flex-wrap;
        @extend .justify-content-center;

        .list-item {
          @extend .px-sm-2;
          @extend .border-sm-left;
          @extend .border-white;

          &:first-child {
            @extend .border-left-0;
          }

          .link {
            color: $white;

            &:hover {
              @extend .text-decoration-none;
              color: $dark-blue;
            }
          }
        }
      }
    }
  }
}
